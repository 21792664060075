<template>
  <div>
    <CCard color="white" text-color="black" class="text-center">
      <CRow>
        <CCol md="6">
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-dark">
                    {{ $t('img') }}
                    <span class="text-danger font-weight-normal">*</span>
                </label>
                <div class="col-sm-9">
                  <CJumbotron v-if="url" color="white" border-color="grey-200">
                    <img alt="" :src="url" width="50%" />
                  </CJumbotron>
                  <CJumbotron color="white" border-color="grey-200" v-else>
                    <div class="custom-file mb-3">
                      <input
                        style="border-color:white"
                        type="file"
                        class="text-dark form-control"
                        required
                        @change="onFileSelect"
                      />
                      <br />
                      <label
                        class="custom-file text-left text-description"
                        style="font-size:12px"
                      >
                        {{ $t('selectedFile') }} ...
                        <span class="text-info text-dark">
                          {{ $t('fileSize') }}
                        </span>
                      </label>
                    </div>
                  </CJumbotron>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-dark">
                    {{ $t('couponName') }}
                    <span class="text-danger font-weight-normal">*</span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-dark" v-model="title" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-dark">
                    {{ $t('detail') }}
                    <span class="text-danger font-weight-dark">*</span>
                </label>
                <div class="col-sm-9">
                  <CTextarea class="text-dark" v-model="description" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-dark">
                    {{ $t('amountPoint') }}
                    <span class="text-danger font-weight-normal">*</span>
                </label>
                <div class="col-sm-9">
                  <CInput
                    class="text-dark"
                    type="number"
                    :placeholder="$t('amountPointPlaceholder')"
                    v-model.number="redeemFor"
                  >
                    <template #append-content>
                      <span style="width:50px">{{ $t('point') }}</span>
                    </template>
                  </CInput>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-dark">
                    {{ $t('couponValue') }}
                    <span class="text-danger font-weight-normal">*</span>
                </label>
                <div class="col-sm-9">
                  <CInput
                    class="text-dark"
                    type="number"
                    :placeholder="$t('couponValuePlacehoolder')"
                    v-model.number="value"
                  >
                    <template #append-content>
                      <span style="width:50px">{{ $t('baht') }}</span>
                    </template>
                  </CInput>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="6">
          <p class="text-left font-weight-normal">
            {{ $t('specifyCouponPeriod') }}
          </p>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-grey hover">
                  <input
                    type="checkbox"
                    v-model="isDuration"
                  />
                  {{ $t('setPeriod') }}
                </label>

                <div class="col-sm-9">
                  <input
                    v-if="isDuration == false"
                    class="text-black form-control"
                    disabled
                  />
                  <v-md-date-range-picker v-else opens="center" :presets="null" :currentLang="getCurrentLang()"
                :locale="getCurrentLang()" @change="handleChangeDate" style="width: 100%;">
                <template slot="input" class="activator-wrapper">
                  <input type="text" readonly="readonly" style="background-color: white;"
                    class="form-control input-group-text" :value="dateText" :placeholder="$t('selectDate')" />
                </template>
              </v-md-date-range-picker>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-grey hover">
                  <input
                    type="checkbox"
                    v-model="isRedeemtionAge"
                  />
                  {{ $t('couponExpiry') }}
                </label>
                <div class="col-sm-9">
                  <multiselect
                  v-model="redeemtionAge"
                  :options="couponTypeOptions"
                  :placeholder="$t('couponExpiration')"
                  label="name"
                  track-by="name"
                  :disabled = !isRedeemtionAge
                  class="font-weight-normal"
                >
                </multiselect>
                </div>
              </div>
            </CCol>
          </CRow>
          <hr />
          <p class="text-left font-weight-normal">{{ $t('couponAmountCondition') }}</p>
          <p class="text-left font-weight-normal text-danger">
            {{ alertRestrict }}
          </p>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-grey hover">
                  <input
                    type="checkbox"
                    v-model="isLimitation"
                    true-value="true"
                    false-value="false"
                  />
                  {{ $t('redeemPerMenber') }}
                  <span
                    style="font-size:12px;float:left;"
                    class="text-description"
                  >
                    {{ $t('limitPerPerson') }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput
                    v-if="isLimitation === 'false'"
                    class="text-black"
                    placeholder="0"
                    disabled
                  >
                    <template #append-content>
                      <span style="width:95px">
                        {{ $t('couponPerMember') }}
                      </span>
                    </template>
                  </CInput>
                  <CInput
                    v-else
                    class="text-black"
                    v-model.number="limitation"
                    placeholder="0"
                    type="number"
                  >
                    <template #append-content>
                      <span style="width:95px">
                        {{ $t('couponPerMember') }}
                      </span>
                    </template>
                  </CInput>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-grey hover">
                  <input
                    type="checkbox"
                    v-model="isBalance"
                  />
                  {{ $t('couponAmount') }}
                </label>
                <div class="col-sm-9">
                  <CInput
                    v-if="isBalance == false"
                    class="text-black"
                    placeholder="0"
                    disabled
                  >
                    <template #append-content>
                      <span style="width:90px">
                        {{ $t('paymentType2') }}
                      </span>
                    </template>
                  </CInput>
                  <CInput
                    v-else
                    v-model.number="balance"
                    class="text-black"
                    placeholder="0"
                    type="number"
                  >
                    <template #append-content>
                      <span style="width:90px">
                        {{ $t('paymentType2') }}
                      </span>
                    </template>
                  </CInput>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" class="text-left">
              <label class="font-weight-normal  text-dark col-form-label hover">
                <input
                  type="checkbox"
                  v-model="isCondition"
                  true-value="true"
                  false-value="false"
                />
               {{ $t('showCouponCondition') }}
              </label>

              <CJumbotron>
                <p class="font-weight-normal">{{ $t('couponUsageCondition') }}</p>
                <p class="text-grey">
                  {{ $t('showCouponToStaff') }} <br />
                  {{ $t('specialRightsNote') }} <br />
                  {{ $t('reservationRights') }}
                </p>
              </CJumbotron>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CButton block color="success" @click="handlePopup()">
        {{ $t('preview') }}
      </CButton>
    </CCard>

    <CModal :show.sync="popupModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center">
        <CCard
          color="white"
          text-color="black"
          class="text-center col-md-12"
          body-wrapper
        >
          <CCard>
            <CCardBody color="light">
              <img alt="" class="img-fluid" :src="url" />
            </CCardBody>
          </CCard>
          <h4 class="font-weight-normal text-left">
            {{ title }}
          </h4>
          <p class="text-left text-description">{{ description }}</p>
          <hr />
          <p class="text-left">
            <em class="fa fa-smile-o"></em>
            {{ $t('usePoint') }} :
            <span class="text-description"> {{ redeemFor }} {{ $t('point') }} : </span>
          </p>
          <p class="text-left" v-if="isDuration == false">
            <em class="fa fa-calendar-check-o"></em>
            {{ $t('couponPeriod') }} :
            <span class="text-description"> {{ $t('noLimitTime') }}</span>
          </p>
          <p class="text-left" v-else-if="isDuration == true">
            <em class="fa fa-calendar-check-o"></em>
            {{ $t('couponPeriod') }} :
            <span class="text-description">
              {{ startAtShow }} {{ $t('to') }} {{ endAtShow }}
            </span>
          </p>
          <hr />
          <br />

          <div v-if="isCondition === true">
            <p class="text-left font-weight-normal">
              {{ $t('couponUsageCondition') }}
            </p>
            <hr />
            <p class="text-left text-description">
              {{ $t('showCouponToStaff') }} <br />
                  {{ $t('specialRightsNote') }} <br />
                  {{ $t('reservationRights') }}
              <br />
              <span v-if="isLimitation === 'true'">
                4. {{ $t('limit') }} {{ limitation }} {{ $t('couponPerMember') }}
              </span>
            </p>
          </div>
          <div v-else-if="isCondition === false && isLimitation === 'true'">
            <p class="text-left font-weight-normal">
              {{ $t('couponUsageCondition') }}
            </p>
            <hr />
            <p class="text-left text-description">
              1. {{ $t('limit') }} {{ limitation }} {{ $t('couponPerMember') }}
            </p>
          </div>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4">
            <CButton
              v-if="loadingButton === true"
              block
              color="success"
              @click="saveCoupon()"
            >
              {{ $t('save') }}
            </CButton>
            <CButton
              v-else-if="loadingButton === false"
              block
              color="success"
              disabled
            >
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="danger" @click="popupModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>

    <CModal :show.sync="imgModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center">
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-12"
          body-wrapper
        >
          <CCardBody color="light">
            <VueCropper
              v-show="selectedFile"
              ref="cropper"
              :src="selectedFile"
              alt="Source Image"
            >
            </VueCropper>
          </CCardBody>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4">
            <CButton
              v-if="loadingButton === true"
              block
              color="success"
              @click="editImg()"
            >
            {{ $t('submit') }}
            </CButton>
            <CButton
              v-else-if="loadingButton === false"
              block
              color="success"
              disabled
            >
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="imgModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>

    <CModal
      :show.sync="alertModal"
      centered
      header="false"
      :footer="footer"
      size="sm"
    >
      <CRow class="justify-content-center">
        <h4
          class="font-weight-normal text-danger text-center"
          v-if="alert !== ''"
        >
          {{ alert }}
          <br />
          <br />
          <CIcon name="cil-warning" size="3xl" />
        </h4>

        <h4
          class="font-weight-normal text-success text-center"
          v-if="isSaveSuccess !== ''"
        >
          {{ isSaveSuccess }}
          <br />
          <br />
          <CIcon name="cil-check-circle" size="3xl" />
        </h4>
      </CRow>
      <template #footer-wrapper>
        <br />
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import crm from '@/services/crm'
import pos from '@/services/pos'
import Multiselect from 'vue-multiselect'

export default {
  components: { VueCropper, DatePicker, Multiselect },
  data() {
    return {
      imgModal: false,
      selectedFile: '',
      cropedImage: '',
      loadingButton: true,
      alert: '',
      url: '',
      title: '',
      description: '',
      redeemFor: 0,
      startAt: '',
      startAtShow: '',
      endAt: '',
      endAtShow: '',
      balance: '',
      value: 0,
      limitation: 0,
      alertRestrict: '',
      isLimitation: 'false',
      isBalance: false,
      isCondition: 'false',
      isDuration: false,
      popupModal: false,
      footer: '',
      image: '',
      imgUrl: '',
      values: [],
      date: [new Date(), new Date()],
      isSaveSuccess: '',
      alertModal: false,
      isRedeemtionAge: false,
      redeemtionAge: {"id": 30, "name": "30 "+this.$i18n.t('minute')},
      dateText: null,
    }
  },
  created() {
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    couponTypeOptions() {
      return [
        {"id": 5, "name": "5 "+this.$i18n.t('minute')},
        {"id": 15, "name": "15 "+this.$i18n.t('minute')},
        {"id": 30, "name": "30 "+this.$i18n.t('minute')},
        {"id": 60, "name": "60 "+this.$i18n.t('minute')},
        {"id": 1440, "name": "1 "+this.$i18n.t('minute')},
        {"id": 10080, "name": "7 "+this.$i18n.t('minute')},
        {"id": 21600, "name": "15 "+this.$i18n.t('minute')}
      ]
    }
  },
  methods: {
    getCurrentLang() {
      return this.$i18n.locale
    },
    handleChangeDate(values) {
      const date = {
        start: moment(values[0]).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        end: moment(values[1]).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      }
      this.dateText = moment(values[0])
        .locale('en')
        .format('DD/MM/YYYY') + " - " +
        moment(values[1])
          .locale('en')
          .format('DD/MM/YYYY')
      this.startAt = date.start
      this.endAt = date.end
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    editImg() {
      this.loadingButton = false
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.uploadImage(this.cropedImage)
      this.loadingButton = true
    },
    onFileSelect(e) {
      const file = e.target.files[0]

      this.imgModal = true

      this.mime_type = file.type
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    handleChange(values) {
      const date = {
        start: moment(values[0]),
        end: moment(values[1]),
      }
      this.startAt = date.start.startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      this.endAt = date.end.endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
    },
    handlePopup() {
      let requirement = this.validateRequirement()

      let validatecondition = this.validateData()

      if (requirement && validatecondition) {
        this.popupModal = true

        //isCondition - กำหนดให้แสดงข้อความในคูปอง สามารถเลือกหรือไม่เลือกก็ได้ ค่าเริ่มต้นเป็นไม่เลือก
        //เก็บค่าเป็น true/false
        if (this.isCondition === 'true') {
          this.isCondition = true
        } else {
          this.isCondition = false
        }

        if (this.isDuration) {
          if (this.startAt === '' && this.endAt === '') {
            this.startAt = moment().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
            this.endAt = moment().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
          }
          this.startAtShow = moment(this.startAt).format('DD/MM/YYYY')
          this.endAtShow = moment(this.endAt).format('DD/MM/YYYY')
        } else {
          this.startAt = ''
          this.endAt = ''
        }
      }
    },
    handleImage(e) {
      const file = e.target.files[0]

      this.createBase64Img(file)
    },
    createBase64Img(fileObject) {
      this.url = URL.createObjectURL(fileObject)
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
        this.uploadImage(this.image)
      }

      reader.readAsDataURL(fileObject)
    },
    uploadImage(image) {
      let data = {
        image: image,
      }

      pos
        .post('/images/v1.0/upload/general/' + this.uid, data)
        .then((res) => {
          if (res.data.error.code === 0) {
            let baseUrl = res.data.data.baseUrl
            let url = res.data.data.url

            this.imgUrl = baseUrl + url
            this.url = this.imgUrl
            this.imgModal = false
            //บันทึกสำเร็จ
          } else {
            //มีบางอย่างผิดพลาด
            console.log(res.data.error.message)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    validateRequirement() {
      if (
        this.title === '' ||
        this.description === '' ||
        this.imgUrl === '' ||
        this.redeemFor === '' ||
        this.value === ''
      ) {
        this.alertModal = true
        this.alert = this.$i18n.t('validateInput')
      } else {
        this.alertModal = false
        this.alert = ''
      }

      return this.alert === ''
    },
    validateData() {
      //isLimitation - กำหนดการรับต่อสมาชิก ซึ่งจะไม่กำหนดก็ได้ กรณีไม่กำหนดให้ค่า limitation = ''
      if (this.isLimitation === 'true') {
        if (this.limitation <= 0) {
          this.alertRestrict = this.$i18n.t('couponAmountValidate')
        } else {
          this.alertRestrict = ''
        }
      } else if (this.isLimitation === 'false') {
        this.limitation = ''
      }

      //isBalance - กำหนดจำนวนคูปอง ซึ่งจะไม่กำหนดก็ได้ กรณีไม่กำหนดให้ค่า balance = ''
      if (this.isBalance == true) {
        if (this.balance <= 0) {
          this.alertRestrict = this.$i18n.t('couponAmountValidate')
        } else {
          this.alertRestrict = ''
        }
      } else if (this.isBalance == false) {
        this.balance = ''
      }

      return this.alertRestrict === ''
    },
    saveCoupon() {
      this.loadingButton = false
      const uid = this.uid
      const users = this.users

      let createdBy = {
        shopName: users.shopName,
        currentPlan: users.currentPlan,
      }
      
      let data = {
        uid: uid,
        imgUrl: this.imgUrl,
        title: this.title,
        description: this.description,
        redeemFor: this.redeemFor,
        value: this.value,
        limitation: this.limitation,
        balance: this.balance,
        createdBy: createdBy,
        updatedBy: createdBy,
        isCondition: this.isCondition,
        isDuration: this.isDuration,
      }
      if (this.isBalance) {
        data['couponAmount'] = this.balance
      } else {
        // Coupon unlimit
        data['couponAmount'] = -1
      }

      if (this.isDuration) {
        data['startAt'] = this.startAt
        data['endAt'] = this.endAt
      }

      if (this.isRedeemtionAge && this.redeemtionAge != null) {
        data['dueDuration'] = this.redeemtionAge.id
        data['dueDurationLabel'] = this.redeemtionAge.name
      }
      
      console.log('data', data)

      crm
        .post('/api/v1.0/' + uid + '/coupon/save', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.loadingButton = true
            this.popupModal = false
            this.alertModal = true
            this.isSaveSuccess = this.$i18n.t('createCouponSuccess')
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          } else {
            this.alertModal = true
            this.alert = this.$i18n.t('errorAlert')
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.redeemtionAge = {"id": 30, "name": "30 "+this.$i18n.t('minute')}
    }
  
  },
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
.hover {
  cursor: pointer;
}
</style>