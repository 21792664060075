<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard color="white" text-color="black">
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol md="12">
                  <p class="text-left font-weight-normal text-dark">
                    {{ $t('generalDetail') }}
                  </p>
                  <CJumbotron border-color="grey-200" color="white" v-if="productIds.length === 0">
                    <CButton @click="productModal = true">
                      + {{ $t('selectdProduct') }}
                    </CButton>
                  </CJumbotron>

                  <CRow v-else-if="productIds.length === 1">
                    <CCol md="12">
                      <div class="form-group">
                        <CCard border-color="greyborder">
                          <table aria-describedby="">
                            <tr>
                              <th scope="col" style="width:50%">
                                <img style="margin:5px;" alt="" class="img-fluid"
                                  :src="productSelected.remoteImagePath" />
                              </th>
                              <td>
                                <CCardBody class="text-left text-dark">
                                  <CButton style="float:right;" @click="clearProduct">
                                    x
                                  </CButton>
                                  <h3>{{ this.productSelected.SKUName || '-' }}</h3>

                                  <hr />

                                  <p>
                                    {{ priceShow }}
                                    ({{ productPLUSelected.SKURatio }}
                                    {{ unitShow }})
                                  </p>
                                </CCardBody>
                              </td>
                            </tr>
                          </table>
                        </CCard>
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-dark">
                      {{ $t('couponName') }}
                      <span class="text-danger font-weight-bold">*</span>
                    </label>
                    <div class="col-sm-9">
                      <CInput v-model="title" name="title" :placeholder="$t('inputCouponName')" required
                        class="justify-content-start" :invalid-feedback="$t('validInputCouponName')">
                      </CInput>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-dark">
                      {{ $t('detail') }}
                      <span class="text-danger font-weight-bold">*</span>
                    </label>
                    <div class="col-sm-9">
                      <CTextarea class="text-dark font-weight-normal" v-model="description"
                        :placeholder="$t('enterDetail')" :invalid-feedback="$t('validEnterDetail')" />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-dark">
                      {{ $t('amountPoint') }}
                      <span class="text-danger font-weight-bold">*</span>
                    </label>
                    <div class="col-sm-9">
                      <CInput class="text-dark" type="number" :placeholder="$t('amountPointPlaceholder')"
                        v-model.number="redeemFor">
                        <template #append-content>
                          <span style="width:50px">{{ $t('points') }}</span>
                        </template>
                      </CInput>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-dark">
                      {{ $t('value') }}
                      <span class="text-danger font-weight-bold">*</span>
                    </label>
                    <div class="col-sm-9">
                      <CInput class="text-dark" type="number" :placeholder="$t('couponValuePlacehoolder')"
                        v-model.number="value">
                        <template #append-content>
                          <span style="width:50px">{{ $t('baht') }}</span>
                        </template>
                      </CInput>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-dark">
                      {{ $t('onhandQtySort') }}
                      <span class="text-danger font-weight-bold">*</span>
                    </label>
                    <div class="col-sm-9">
                      <CInput class="text-black" type="number" :placeholder="$t('onhandQtySortPlaceholder')"
                        v-model.number="quantity">
                        <template #append-content>
                          <span style="width:50px">{{ $t('perUnit') }}</span>
                        </template>
                      </CInput>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <p class="text-left font-weight-normal">
                {{ $t('specifyCouponPeriod') }}
              </p>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-grey hover">
                      <input type="checkbox" v-model="isDuration" true-value="true" false-value="false" />
                      {{ $t('setPeriod') }}
                    </label>

                    <div class="col-sm-9">
                      <input v-if="isDuration === 'false'" class="text-black form-control" disabled />
                      <v-md-date-range-picker v-else opens="center" :presets="null" :currentLang="getCurrentLang()"
                        :locale="getCurrentLang()" @change="handleChangeDate" style="width: 100%;">
                        <template slot="input" class="activator-wrapper">
                          <input type="text" readonly="readonly" style="background-color: white;"
                            class="form-control input-group-text" :value="dateText" :placeholder="$t('selectDate')" />
                        </template>
                      </v-md-date-range-picker>

                    </div>
                  </div>
                </CCol>
              </CRow>
              <hr />
              <p class="text-left font-weight-normal">{{ $t('couponAmountCondition') }}</p>
              <p class="text-left font-weight-normal text-danger">
                {{ alertRestrict }}
              </p>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-grey hover">
                      <input type="checkbox" v-model="isLimitation" true-value="true" false-value="false" />
                      {{ $t('redeemPerMenber') }}
                      <span style="font-size:12px;float:left;" class="text-description">
                        {{ $t('limitPerPerson') }}
                      </span>
                    </label>
                    <div class="col-sm-9">
                      <CInput v-if="isLimitation === 'false'" class="text-grey" placeholder="0" disabled>
                        <template #append-content>
                          <span style="width:90px">
                            {{ $t('couponPerMember') }}
                          </span>
                        </template>
                      </CInput>
                      <CInput v-else class="text-black" v-model.number="limitation" placeholder="0" type="number">
                        <template #append-content>
                          <span style="width:90px">
                            {{ $t('couponPerMember') }}
                          </span>
                        </template>
                      </CInput>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-left text-grey hover">
                      <input type="checkbox" v-model="isBalance" true-value="true" false-value="false" />
                      {{ $t('couponAmount') }}
                    </label>
                    <div class="col-sm-9">
                      <CInput v-if="isBalance === 'false'" class="text-black" placeholder="0" disabled>
                        <template #append-content>
                          <span style="width:90px">
                            {{ $t('coupon') }}
                          </span>
                        </template>
                      </CInput>
                      <CInput v-else v-model.number="balance" class="text-black" placeholder="0" type="number">
                        <template #append-content>
                          <span style="width:90px">
                            {{ $t('coupon') }}
                          </span>
                        </template>
                      </CInput>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12" class="text-left">
                  <label class="font-weight-normal col-form-label text-dark hover">
                    <input type="checkbox" v-model="isCondition" true-value="true" false-value="false" />
                    {{ $t('showCouponCondition') }}
                  </label>

                  <CJumbotron>
                    <p class="font-weight-normal">{{ $t('couponUsageCondition') }}</p>
                    <p class="text-grey">
                      {{ $t('showCouponToStaff') }} <br />
                      {{ $t('specialRightsNote') }}
                      <br />
                      {{ $t('reservationRights') }}
                    </p>
                  </CJumbotron>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CButton block color="success" class="px-4" @click="handlePopup()">
            {{ $t('preview') }}
          </CButton>
        </CCard>
      </CCol>
    </CRow>

    <CModal :show.sync="popupModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center">
        <CCard color="white" text-color="dark" class="text-center col-md-12" body-wrapper>
          <CCard>
            <CCardBody color="light">
              <div>
                <img alt="" class="img-fluid" :src="imgUrl" />
              </div>
            </CCardBody>
          </CCard>
          <h4 class="font-weight-bold text-left">
            {{ title }}
          </h4>
          <p class="text-left">{{ description }}</p>
          <hr />
          <p class="text-left ">
            <em class="fa fa-smile-o"></em>
            <strong> {{ $t('usePoint') }} : </strong>
            {{ redeemFor }} {{ $t('points') }}
          </p>
          <p class="text-left" v-if="isDuration === 'false'">
            <em class="fa fa-calendar-check-o font-weight-normal"></em>
            {{ $t('couponPeriod') }} :
            {{ $t('nolimitTimeOrUntilNoStock') }}
          </p>
          <p class="text-left" v-else-if="isDuration === 'true'">
            <em class="fa fa-calendar-check-o"></em>
            <strong> {{ $t('couponPeriod') }} : </strong>
            {{ startAtShow }} {{ $t('to') }} {{ endAtShow }}
          </p>
          <hr />
          <br />
          <div v-if="isCondition === true">
            <p class="text-left font-weight-bold">
              {{ $t('couponUsageCondition') }}
            </p>
            <hr />
            <p class="text-left">
              {{ $t('showCouponToStaff') }} <br />
              {{ $t('specialRightsNote') }}
              <br />
              {{ $t('reservationRights') }}
              <br />
              <span v-if="isLimitation === 'true'">
                4. {{ $t('limit') }} {{ limitation }} {{ $t('couponPerMember') }}
              </span>
            </p>
          </div>
          <div v-else-if="isCondition === false && isLimitation === 'true'">
            <p class="text-left font-weight-bold">
              {{ $t('couponUsageCondition') }}
            </p>
            <hr />
            <p class="text-left">
              1. {{ $t('limit') }} {{ limitation }} {{ $t('couponPerMember') }}
            </p>
          </div>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4">
            <CButton v-if="loadingButton === true" block color="success" @click="saveCoupon()">
              {{ $t('save') }}
            </CButton>
            <CButton v-else-if="loadingButton === false" block color="success" disabled>
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="danger" @click="popupModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>

    <CModal :show.sync="alertModal" centered header="false" :footer="footer" size="sm">
      <CRow class="justify-content-center">
        <h4 class="font-weight-bold text-danger text-center" v-if="alert !== ''">
          {{ alert }}
          <br />
          <br />
          <CIcon name="cil-warning" size="3xl" />
        </h4>

        <h4 class="font-weight-bold text-success text-center" v-if="isSaveSuccess !== ''">
          {{ isSaveSuccess }}
          <br />
          <br />
          <CIcon name="cil-check-circle" size="3xl" />
        </h4>
      </CRow>
      <template #footer-wrapper>
        <br />
      </template>
    </CModal>

    <!-- Choose product -->
    <CModal color="white" :show.sync="productModal" size="lg" centered :title="$t('selectdProduct')" :footer="footer"
      :closeOnBackdrop="closeOnBackdrop">
      <!-- เลือกสาขา -->
      <CRow>
        <CCol lg="2" md="6" sm="12" col="12">
          <CButton block color="light">
            {{ $t('selectshop') }}
          </CButton>
        </CCol>
        <CCol lg="10" md="6" col="12">
          <select class="form-control hover" v-model="shopObjectId" @change="getProduct()">
            <option v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
              {{ shop.branchName }}
            </option>
          </select>
        </CCol>
      </CRow>

      <!-- ค้นหาสินค้า -->
      <hr />
      <CRow>
        <CCol col="12" lg="10">
          <div>
            <CInput v-model.trim="keyword" class="cursor" :placeholder="$t('searchProduct') + '...'"
              @input="getProduct()" />
          </div>
        </CCol>
        <CCol col="12" lg="2">
          <CButton v-on:click="getProduct()" block color="success">
            {{ $t('search') }}
          </CButton>
        </CCol>
      </CRow>
      <!-- <CRow>
        <CCol sm="12" lg="12">
          <div v-if="loadingButton === true">
            <table class="table table-striped" aria-describedby="" >
              <tbody>
                <tr v-for="(item, index) in products" :key="index">
                  <th class="text-left" style="width:55px" scope="row">
                    <input
                      type="checkbox"
                      v-model="productIds"
                      :value="item.objectId"
                      @change="select(item, $event)"
                      class="form-control form-control-sm"
                    />
                  </th>
                  <td style="width:10%">
                    <CImg
                      v-if="item.remoteImagePath !== undefined"
                      :src="item.remoteImagePath"
                      width="60px"
                      style="border-radius: 5px"
                    />
                    <div
                      v-else
                      style="border-radius: 5px"
                      :style="{
                        width: '60px',
                        height: '50px',
                        backgroundColor: item.indexColor,
                      }"
                    ></div>
                  </td>
                  <td class="text-left">
                    <h6 class="font-weight-bold text-dark">
                      {{ item.SKUName }} ( {{ unitName(item.unit) }} )
                    </h6>

                    <h6 class="text-dark">
                      THB {{ item.SKUPrice }}
                      <CBadge
                        v-if="item.isOnScreen"
                        color="success"
                        shape="pill"
                      >
                        แสดงบนหน้าขาย
                      </CBadge>
                      <CBadge v-if="item.favorite" color="success" shape="pill">
                        สินค้านิยม
                      </CBadge>
                    </h6>
                  </td>
                  <td class="text-right">
                    <h6 class="text-dark">
                      ยอดคงเหลือ
                    </h6>
                    <h6
                      v-if="onhandQty(item.ProductSKUStock) < 0"
                      class="text-danger"
                    >
                      <strong> {{ onhandQty(item.ProductSKUStock) }}</strong>
                    </h6>
                    <h6 v-else class="text-dark">
                      <strong>{{ onhandQty(item.ProductSKUStock) }}</strong>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CListGroupItem v-if="loadingButton === false">
            <div class="text-center" width="100%">
              <button
                class="btn"
                type="button"
                disabled
                style="color: darkcyan"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                >
                </span>
                กำลังโหลด . . .
              </button>
            </div>
          </CListGroupItem>
        </CCol>
      </CRow>
      <br />
      <template #header-wrapper>
        <br />
      </template> -->
      <!--  -->

      <CRow class="mt-3">
        <CCol sm="12" lg="12">
          <div v-if="loadingButton == true">
            <CDataTable :fields="fields" :items="items" striped hover clickableRows style="white-space: nowrap;"
              @row-clicked="toggleDetails">
              <template #remoteImagePath="{ item }">
                <td>
                  <div v-if="item.remoteImagePath" class="square-box" :style="{
                    'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                    'background-size': 'cover',
                    'background-position': 'center center',
                    'width': 'auto',
                    'height': '40px',
                    'border-radius': '3px',
                    'aspect-ratio': '1.3'
                  }"></div>
                  <div v-else class="square-box" :style="{
                    backgroundColor: item.backgroundColor,
                    width: 'auto',
                    'background-origin': 'content-box',
                    'border-radius': '3px',
                    'height': '40px',
                    'aspect-ratio': '1.3'
                  }"></div>
                </td>
              </template>
              <template #onhandQty="{ item }">
                <td class="text-right">
                  <h6 v-if="item.onhandQty < 0" class="text-danger">
                    {{ item.onhandQty }}
                  </h6>
                  <h6 v-else>
                    {{ item.onhandQty }}
                  </h6>
                </td>
              </template>
              <template #details="{ item }" @click="toggleDetails(item)">
                <CCollapse :show="details.includes(item)">
                  <CCardBody border-color="light">
                    <div class="h6 font-weight-normal text-left text-dark"> {{ $t('PLU') }} </div>
                    <table style="width:100%" class="table table-borderless text-left">
                      <th></th>
                      <th class="font-weight-normal text-dark" style="width:20%"> {{ $t('pluCode') }} </th>
                      <th class="font-weight-normal text-dark">{{ $t('productName') }} </th>
                      <th style="width:10%" class="font-weight-normal text-right text-dark">
                        {{ $t('SKURatio') }}
                      </th>
                      <tr v-for="ProductPLU in item.ProductPLU" :key="ProductPLU.id">
                        <td style="width: 5px; vertical-align: middle;">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input success cursor"
                              v-model="ProductPLU.selected" @change="select(item, $event)" :id=ProductPLU.id />
                            <label class="custom-control-label cursor" :for=ProductPLU.id>
                            </label>
                          </div>
                        </td>
                        <td style="vertical-align: middle;">
                          <div class="text-dark">{{ ProductPLU.PLUCode }}</div>
                        </td>
                        <td style="vertical-align: middle;">
                          <div class="text-dark">{{ ProductPLU.name }}</div>
                        </td>
                        <td style="vertical-align: middle;">
                          <div class="text-dark text-right">{{ ProductPLU.SKURatio }}</div>
                        </td>
                      </tr>
                    </table>
                  </CCardBody>
                </CCollapse>
              </template>
              <template #no-items-view>
                <div class="text-center my-5">
                  <h4 style="color: #ced2d8;">
                    no item
                  </h4>
                </div>
              </template>
            </CDataTable>
          </div>
          <CListGroupItem v-if="loadingButton == false">
            <div class="text-center" width="100%">
              <button class="btn" type="button" disabled style="color: darkcyan">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                </span>
                {{ $t('loading') }}
              </button>
            </div>
          </CListGroupItem>
        </CCol>
      </CRow>

      <!--  -->
      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12 mt-2">
          <pagination :meta_data="meta_data" v-on:next="getProduct">
          </pagination>
        </CRow>
        <CRow class="justify-content-center col-12">
          <CCol md="6">
            <CButton color="success" class="text-white" @click="confirmProduct()" block>
              {{ $t('confirmItem') }}
            </CButton>
          </CCol>
          <CCol md="6">
            <CButton color="dark" block @click="clearProduct()">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
      </template>
    </CModal>

    <CModal color="white" :show.sync="productPLUModal" size="sm" centered :footer="footer"
      :closeOnBackdrop="closeOnBackdrop">
      <template #header-wrapper>
        <br />
      </template>

      <p class="font-weight-bold">{{ $t('selectProductForAdjustStock') }}</p>
      <CListGroup>
        <div v-for="PLU in productSelected.ProductPLU" :key="PLU.objectId">
          <CListGroupItem class="text-left" v-if="PLU.deleted_at === undefined">
            <input type="checkbox" :value="PLU" v-model="productPLUIds" @change="confirmProductPLU(PLU, $event)" />
            <strong> {{ PLU.PLUCode }}</strong> - {{ PLU.SKURatio }}
            {{ unitShow }}
          </CListGroupItem>
        </div>
      </CListGroup>

      <template #footer-wrapper>
        <br />
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Pagination from '@/containers/Pagination'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import crm from '@/services/crm'
import pos from '@/services/pos'
import util from '@/util/util'


export default {
  components: { DatePicker, Pagination },
  data() {
    return {
      loadingButton: true,
      alert: '',
      url: '',
      title: null,
      description: '',
      redeemFor: null,
      startAt: '',
      startAtShow: '',
      endAt: '',
      endAtShow: '',
      balance: '',
      value: null,
      limitation: 0,
      alertRestrict: '',
      isLimitation: 'false',
      isBalance: 'false',
      isCondition: 'false',
      isDuration: 'false',
      popupModal: false,
      footer: '',
      values: [],
      date: [new Date(), new Date()],
      isSaveSuccess: '',
      alertModal: false,
      productModal: false,
      shopObjectId: '',
      products: [],
      keyword: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      productSelected: {},
      productIds: [],
      checkedValue: '',
      priceShow: '',
      unitShow: '',
      closeOnBackdrop: false,
      imgUrl: '',
      quantity: null,
      productPLUModal: false,
      productPLUSelected: {},
      productPLUIds: [],
      details: [],
      selectedItems: {},
      dateText: null,
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    fields() {
      return [
        { key: 'index', label: '#', _style: 'width:2%', _classes: 'text-dark font-weight-normal' },
        {
          key: 'remoteImagePath',
          label: this.$i18n.t('product'),
          _style: 'width:5%',
          _classes: 'text-left text-dark font-weight-normal'
        },
        { key: 'SKUName', label: '', _classes: 'text-left font-weight-normal text-dark' },
        {
          key: 'unit',
          label: this.$i18n.t('unit'),
          _style: 'width:10%',
          _classes: 'text-dark text-left font-weight-normal'
        },
        {
          key: 'category',
          label: this.$i18n.t('category'),
          _style: 'width:15%',
          _classes: 'text-dark text-left font-weight-normal'
        },
        {
          key: 'price',
          label: this.$i18n.t('price'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:10%',
        },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right text-dark font-weight-normal ',
          _style: 'width:10%',
        },
      ]
    },
    items() {
      let data = this.products || []
      console.log(data)
      let objects = []
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 25

      for (let i = 0; i < data.length; i++) {
        const object = data[i]
        const index = (currentPage * limit) + (i + 1)

        // required: product sku stock
        if (object.ProductSKUStock == undefined) {
          continue
        }

        let remoteImagePath = ''
        let backgroundColor = ''
        if (object.remoteImagePath != undefined) {
          remoteImagePath = object.remoteImagePath
          backgroundColor = ''
        } else {
          remoteImagePath = ''
          backgroundColor = util.generateColor(object.indexColor)
        }

        let item = {
          'index': index,
          'remoteImagePath': remoteImagePath,
          'backgroundColor': backgroundColor,
          'SKUName': object.SKUName,
          'onhandQty': object.ProductSKUStock.onhandQty || 0,
          'price': '฿ ' + object.SKUPrice,
          'isMenu': object.isMenu,
          'ProductPLU': object.ProductPLU,
          'objectId': object.objectId,
          'id': object.id,
        }
        if (object.category !== undefined) {
          item['category'] = object.category.name || 'N/A'
        } else {
          item['category'] = 'N/A'
        }
        if (object.unit !== undefined) {
          item['unit'] = object.unit.name || 'N/A'
        } else {
          item['unit'] = 'N/A'
        }

        objects.push(item)
      }
      return objects
    }
  },
  created() {
    this.getProduct()
  },
  methods: {
    ...util,
    handleChangeDate(values) {
      const date = {
        start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
      }
      this.dateText = moment(values[0])
        .locale('en')
        .format('DD/MM/YYYY') + " - " +
        moment(values[1])
          .locale('en')
          .format('DD/MM/YYYY')
      this.startAt = date.start
      this.endAt = date.end
    },
    getCurrentLang() {
      return this.$i18n.locale
    },
    onhandQty(productSKUStock) {
      if (productSKUStock !== undefined) {
        if (productSKUStock.onhandQty) {
          return productSKUStock.onhandQty
        } else {
          return 'non-stock'
        }
      } else {
        return 'non-stock'
      }
    },
    unitName(unit) {
      if (unit !== undefined) {
        if (unit.name) {
          return unit.name
        }
      } else {
        return ''
      }
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    confirmProduct() {
      if (this.productIds.length === 1) {
        this.productModal = false
      } else {
        this.productModal = true
      }
    },
    clearProduct() {
      this.productModal = false
      this.productSelected = {}
      this.productIds = []
      this.value = 0
    },
    confirmProductPLU(product, event) {
      if (event.target.checked === false) {
        this.productPLUSelected = {}
        this.productPLUIds = []
      } else if (event.target.checked === true) {
        if (this.productPLUIds.length >= 1) {
          this.productPLUIds = []
        }
        this.productPLUSelected = product
        this.productPLUIds.push(product.objectId)
      }

      if (this.productPLUIds.length === 1) {
        this.productPLUModal = false
      }
    },
    clearProductPLU() {
      this.productPLUModal = false
    },
    select(item, event) {
      if (event.target.checked === false) {
        this.productSelected = {}
        this.productIds = []
        this.value = 0
      } else if (event.target.checked === true) {
        // this.productPLUModal = true
        if (this.productIds.length >= 1) {
          this.productIds = []
        }
        this.productSelected = item
        this.productIds.push(item.objectId)
        this.value = item.SKUPrice
        this.unitShow = item.unit || ''
        this.priceShow = item.price
        this.imgUrl = item.remoteImagePath
      }
    },
    getProduct(page = 1) {
      if (this.shopObjectId === '') {
        this.shopObjectId = this.shops[0].objectId
      }
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      let params = {
        shopObjectId: shopObjectId,
        page: page,
        searchWord: this.keyword,
        limit: 25,
      }

      pos
        .get('/api/v1.0/' + uid + '/Shop/getproduct/data', { params })
        .then((res) => {
          this.products = res.data.data
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.loadingButton = true
        })
    },
    handleChange(values) {
      const date = {
        start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
      }
      this.startAt = date.start
      this.endAt = date.end
    },
    handlePopup() {
      let requirement = this.validateRequirement()

      let validatecondition = this.validateData()

      if (requirement && validatecondition) {
        this.popupModal = true

        //isCondition - กำหนดให้แสดงข้อความในคูปอง สามารถเลือกหรือไม่เลือกก็ได้ ค่าเริ่มต้นเป็นไม่เลือก
        //เก็บค่าเป็น true/false
        if (this.isCondition === 'true') {
          this.isCondition = true
        } else {
          this.isCondition = false
        }

        if (this.isDuration === 'true') {
          if (this.startAt === '' && this.endAt === '') {
            this.startAt = new Date()
            this.endAt = new Date()
          }
          this.startAtShow = moment(this.startAt).format('DD/MM/YYYY')
          this.endAtShow = moment(this.endAt).format('DD/MM/YYYY')
        } else if (this.isDuration === 'false') {
          this.startAt = ''
          this.endAt = ''
        }
      }
    },
    validateRequirement() {
      if (this.title == '' || this.title == null) {
        this.alertModal = true
        this.alert = this.$i18n.t('vilidCouponName')
        return false
      }

      if (this.productIds.length === 0) {
        this.alertModal = true
        this.alert = this.$i18n.t('selectProductPlease')
        return false
      }

      if (this.description === '') {
        this.alertModal = true
        this.alert = this.$i18n.t('validEnterDetail')
        return false
      }

      if (this.redeemFor == '' || this.redeemFor == null) {
        this.alertModal = true
        this.alert = this.$i18n.t('validAmountPoint')
        return false
      }

      if (this.value == '' || this.value == null) {
        this.alertModal = true
        this.alert = this.$i18n.t('validCouponValue')
        return false
      }

      if (this.quantity <= 0) {
        this.alertModal = true
        this.alert = this.$i18n.t('validItemAmount')
        return false
      }

      this.alertModal = false
      this.alert = ''
      return true
    },
    validateData() {
      //isLimitation - กำหนดการรับต่อสมาชิก ซึ่งจะไม่กำหนดก็ได้ กรณีไม่กำหนดให้ค่า limitation = ''
      if (this.isLimitation === 'true') {
        if (this.limitation <= 0) {
          this.alertRestrict = this.$i18n.t('couponAmountValidate')
        } else {
          this.alertRestrict = ''
        }
      } else if (this.isLimitation === 'false') {
        this.limitation = ''
      }

      //isBalance - กำหนดจำนวนคูปอง ซึ่งจะไม่กำหนดก็ได้ กรณีไม่กำหนดให้ค่า balance = ''
      if (this.isBalance === 'true') {
        if (this.balance <= 0) {
          this.alertRestrict = this.$i18n.t('couponAmountValidate')
        } else {
          this.alertRestrict = ''
        }
      } else if (this.isBalance === 'false') {
        this.balance = ''
      }

      return this.alertRestrict === ''
    },
    validDescription(val) {
      return val ? val.length >= 6 : false
    },
    validUsePoint(val) {
      return val ? val > 0 : false
    },
    saveCoupon() {
      this.loadingButton = false
      const uid = this.uid
      const users = this.users

      let createdBy = {
        shopName: users.shopName,
        currentPlan: users.currentPlan,
      }

      let item = {
        objectId: this.productSelected.objectId,
        id: this.productSelected.id,
        shop: this.productSelected.shop,
        ProductSKUStock: this.productSelected.ProductSKUStock,
        ProductPLU: this.productPLUSelected,
      }

      let data = {
        uid: uid,
        imgUrl: this.imgUrl,
        title: this.title,
        description: this.description,
        redeemFor: this.redeemFor,
        value: this.value,
        startAt: this.startAt,
        endAt: this.endAt,
        limitation: this.limitation,
        balance: this.balance,
        couponAmount: this.balance,
        createdBy: createdBy,
        updatedBy: createdBy,
        isCondition: this.isCondition,
        type: 1,
        item: item,
        quantity: this.quantity,
      }

      crm
        .post('/api/v1.0/' + uid + '/coupon/save', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            //บันทึกสำเร็จ
            this.loadingButton = true
            this.popupModal = false
            this.alertModal = true
            this.isSaveSuccess = this.$i18n.t('createCouponSuccess')
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          } else {
            //มีบางอย่างผิดพลาด
            this.alertModal = true
            this.alert = this.$i18n.t('errorAlert')
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    toggleDetails(index) {
      const position = this.details.indexOf(index)
      position !== -1
        ? this.details.splice(position, 1)
        : this.details.push(index)
    },
    onSelectedAutoCompleteEvent(item) {
      console.log('onSelectedAutoCompleteEvent')
    }
  },
}
</script>
<style>
.vali {
  position: absolute;
  margin: -7px;
  color: red;
}

.hover {
  cursor: pointer;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #3abc98;
  background-color: #3abc98;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.1rem;
}
</style>